import { Component, OnInit,  Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { VitalsPageService } from 'src/app/modules/task-module/vitals-page/vitals-page.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-spirometer',
  templateUrl: './spirometer.component.html',
  styleUrls: ['./spirometer.component.scss']
})


export class SpirometerComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  spiroChart: Highcharts.Chart | undefined;
  spiroSeries: Highcharts.Series | undefined;


  spiroLiveSeries: any = [];
  spiroSeriesData: any = [];
  spiroReadingCount: any =0;
  spirotable_count: any= 0;

  isFirstGraphShowing: any = true;


  constructor(public api: VitalsPageService, private renderer: Renderer2, private el: ElementRef) {

    this.api.spiroChartData.subscribe((response: any) => {
      console.log("spirometer hit Saheb");
      console.log("spirometer hit and response Saheb :" +response);

      if (response == 'emit') {
        this.createSpiroLiveGraph('');
      }
      else if(response == 'emitTableData'){
        this.generateSpiroSeriesData('');
      }
    }, (error: any) => {
      console.error("Error in subscription:", error); // Log any error from the observable
    })
    
    this.api.chartClose.subscribe((response: any) => {
      
      if (response === 'clear') {
        this.api.spiroMeterReadings = [];
        this.spiroLiveSeries = [];

        this.api.spiroMeterReadingsForTable = [];
        this.spiroSeriesData = [];
        this.spiroReadingCount = 0;
        this.spirotable_count = 0;
      }
    })
  }
  ngOnInit(): void {
  }

  createSpiroLiveGraph(datas: string): void {
    this.isFirstGraphShowing = true;
    let arr: number[] = this.api.spiroMeterReadings;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        this.spiroLiveSeries.push(arr[i]);
      }
    }

    if(this.spiroLiveSeries.length > 0){
      this.showLiveSpiroGraph();
    }
    

  }

  showLiveSpiroGraph(): void {
    // const container = document.getElementById('spiroLiveGraph');
    // console.log('Container:', container); // Add this line to check if the container exists
  
    // if (container) {
      Highcharts.chart('spiroLiveGraph', {
      chart: {
        type: 'spline',
        events: {
          load: () => {
            if (this.spiroChart) {
              this.spiroSeries = this.spiroChart.series[0];
            }
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        tickPixelInterval: 100,
        min: 0,
        max: 6000
      },

      yAxis: {
        min: 0,
        max: 16777215,
        tickInterval: 5000,
        visible: false,
        title: {
          text: 'Flow'
        }
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      series: [{
        type: 'spline',
        color: 'blue',
        data: this.spiroLiveSeries
      }],
      scrollbar: {
        enabled: true,
        barBackgroundColor: 'gray',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: 'gray',
        buttonBorderWidth: 0,
        buttonArrowColor: 'yellow',
        buttonBorderRadius: 7,
        rifleColor: 'yellow',
        trackBackgroundColor: 'white',
        trackBorderWidth: 1,
        trackBorderColor: 'silver',
        trackBorderRadius: 7
      }
    } as Highcharts.Options);
    // } else {
    //   console.error('Chart container not found');
    // }

   
  }

  generateSpiroSeriesData(datas: string): void {
    this.isFirstGraphShowing = false;

    let arr: any = this.api.spiroMeterReadingsForTable;

    if(this.api.spiroMeterReadingsForTable.length >= 3){
       
        this.spiroSeriesData = this.mergeArray( arr[0], arr[1]);
      
        if(this.spiroSeriesData.length > 0){
          this.plotSpiroChart();
        }

        
        this.spiroReadingCount++;
        var colors = ["#00ff00", "#0000ff", "#ff0000","#d341f4","#f4418b"];

      let tableId="Table_"+this.spiroReadingCount;
      this.clearTables();
      if(arr[2].length > 0){
        this.createSpiroTable(arr[2],colors[this.spiroReadingCount-1],tableId);
      }
    }

  }

  private clearTables(): void {
    const spiroTable1 = this.el.nativeElement.querySelector('#spiroTable1');
    const spiroTable2 = this.el.nativeElement.querySelector('#spiroTable2');
    const spiroTable3 = this.el.nativeElement.querySelector('#spiroTable3');

    if (spiroTable1) {
      while (spiroTable1.firstChild) {
        spiroTable1.removeChild(spiroTable1.firstChild);
      }
    }
    if (spiroTable2) {
      while (spiroTable2.firstChild) {
        spiroTable2.removeChild(spiroTable2.firstChild);
      }
    }
    if (spiroTable3) {
      while (spiroTable3.firstChild) {
        spiroTable3.removeChild(spiroTable3.firstChild);
      }
    }
  }
  createSpiroTable(tableData: number[], color: string, tableId: string) {
  
    this.spirotable_count++;
    const len = 3;
    const FVC = tableData[0].toFixed(len);
    const FEV1 = tableData[1].toFixed(len);
    const FEV1_per = tableData[2].toFixed(len);
    const FEF25 = tableData[3].toFixed(len);
    const FEF50 = tableData[4].toFixed(len);
    const FEF75 = tableData[5].toFixed(len);
    const PEF = tableData[6].toFixed(len);
    const TV = tableData[7].toFixed(len);
    const TLC = tableData[8].toFixed(len);

    console.log("inside createSpiroTable spirotable_count...." + this.spirotable_count);

    const table = this.renderer.createElement('table');
    this.renderer.setAttribute(table, 'id', tableId);
    this.renderer.setAttribute(table, 'border', '1');
    this.renderer.setStyle(table, 'width', '100%');
    this.renderer.setStyle(table, 'table-layout', 'fixed');
    const tbody = this.renderer.createElement('tbody');
    table.appendChild(tbody);

    const tr1 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr1, 'display', 'flex');
    tbody.appendChild(tr1);

    this.appendCell(tr1, 'FVC', FVC + ' L', color);
    this.appendCell(tr1, 'FEV1', FEV1 + ' L', color);
    this.appendCell(tr1, 'FEV1%', FEV1_per, color);

    const tr2 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr2, 'display', 'flex');
    tbody.appendChild(tr2);

    this.appendCell(tr2, 'FEF25', FEF25 + ' L/sec', color);
    this.appendCell(tr2, 'FEF50', FEF50 + ' L/sec', color);
    this.appendCell(tr2, 'FEF75', FEF75 + ' L/sec', color);

    const tr3 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr3, 'display', 'flex');
    tbody.appendChild(tr3);

    this.appendCell(tr3, 'PEF', PEF + ' L/sec', color);
    this.appendCell(tr3, 'TV', TV + ' L', color);
    this.appendCell(tr3, 'TLC', TLC + ' L', color);

    if (this.spirotable_count === 1) {
      const spiroTable1 = this.el.nativeElement.querySelector('#spiroTable1');
      if (spiroTable1) {
        this.renderer.appendChild(spiroTable1, table);
      }
      spiroTable1.style.display = 'block';
    }
    if (this.spirotable_count === 2) {
      const spiroTable2 = this.el.nativeElement.querySelector('#spiroTable2');
      if (spiroTable2) {
        this.renderer.appendChild(spiroTable2, table);
      }
      spiroTable2.style.display = 'block';
    }
    if (this.spirotable_count === 3) {
      const spiroTable3 = this.el.nativeElement.querySelector('#spiroTable3');
      if (spiroTable3) {
        this.renderer.appendChild(spiroTable3, table);
      }
      spiroTable3.style.display = 'block';
    }
  }

  private appendCell(row: HTMLElement, label: string, value: string, color: string) {
    const th = this.renderer.createElement('th');
    this.renderer.setStyle(th, 'color', '#6d0419');
    this.renderer.setStyle(th, 'border', '1px solid black');
    this.renderer.setStyle(th, 'font-size', '12px');
    this.renderer.setStyle(th, 'width', '33.33%');  // Set the desired width
    th.innerText = label;
    row.appendChild(th);

    const td = this.renderer.createElement('td');
    this.renderer.setStyle(td, 'color', color);
    this.renderer.setStyle(td, 'font-weight', 'bold');
    this.renderer.setStyle(td, 'border', '1px solid black');
    this.renderer.setStyle(td, 'font-size', '12px');
    this.renderer.setStyle(td, 'width', '33.34%');  // Set the desired width
    td.innerText = value;
    row.appendChild(td);
  }


  mergeArray(x:[],y:[]){
    var xy = [];
    for (var i = 0; i < x.length && i < y.length; i++) {
        xy[i] = [x[i], y[i]];
    }
   
    return xy;
  }

  plotSpiroChart(): void {
    
    Highcharts.chart('spiroGraph', {
      xAxis: {
        minPadding: 0.05,
        maxPadding: 0.05,
        title: {
            text: 'Volume (L)',
            style: {
                fontSize: '18px'
        }
        },
      },
      yAxis: {
        
          title: {
              text: 'Flow ( liters per second )',
              //align: 'center'
              style: {
                  fontSize: '18px'
              }
          }
      },
      title: {
        text: ''
      },
      plotOptions: {
          series: {
              allowPointSelect: true,
              showInLegend: false   
          }
      },
     // series: this.spiroSeriesData
      series: [{
        type: 'spline',
        color: 'green',
        data: this.spiroSeriesData
      }]
    } as Highcharts.Options);
   }

   generatePdf(){
    // const doc = new jsPDF();
    // doc.text('Spirometer Reading', 10, 10);
    // const element = document.getElementById('graphs');
    // if (element) {
    //   html2canvas(element).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     doc.addImage(imgData, 'PNG', 10, 20, 180, 160);
    //     doc.save('spirometer.pdf');
    //   });
    // } else {
    //   doc.save('spirometer.pdf');
    // }
    const element = document.getElementById('graphs');

    if (element) {
      // Capture the full height of the element
      html2canvas(element, {
        scale: 2, // Higher scale for better quality
        scrollX: 0,
        scrollY: -window.scrollY, // To handle elements with vertical scrolling
        windowWidth: document.body.scrollWidth, // Capture full width
        windowHeight: element.scrollHeight // Capture full height of the element
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0); // 1.0 ensures highest quality
  
        // Create a link to download the image
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'spirometer.jpg'; // Set the name of the downloaded image
  
        // Trigger the download
        link.click();
      }).catch((error) => {
        console.error('Error capturing the canvas:', error);
      });
    } else {
      console.error('Element with ID "graphs" not found.');
    }
  }


}
